






































































































































































































import { Vue, Component, Mixins, Watch } from 'vue-property-decorator'
import HeaderBox from '@/components/HeaderBox.vue'
import UploadImage from '@/components/UploadImage.vue'
import ReviewTraces from '@/components/ReviewTraces.vue'
import ImagePreview from '@/components/ImagePreview.vue'
import url from '@/common/url';

interface SearchParams {
  status: string
  page: number
  count: number
}
interface traceForm {
  title: string,
  customTraceTitle: string
  image_urls: string[]
  remark: string
}
@Component({
  components: {
    HeaderBox,
    UploadImage,
    ReviewTraces,
    ImagePreview
  },
  filters: {
    filterStatus (val) {
      return val ? '是' : '否'
    },
    formatStatus (val) {
      return ['待开拍', '竞拍中', '已拍出', '已流拍', '已成交', '已关闭', '已下架', '待审核', '已驳回'][+val-1]
    },
    formatOutboundType (val) {
      // 1:交车出库 2：其它出库 3：退回门店
      return ['交车出库', '其它出库', '退回门店'][+val - 1]
    }
  }
})
export default class CarInfo extends Vue {
  loading: boolean = false
  // uploadUrl: string = '/api/v1/source_handler/upload/image/manual_operate_trace'
  carInfo = {}
  bidRecords = [{}]
  searchParams: SearchParams = {
    status: '0',
    page: 1,
    count: 10
  }
  totalNum: number = 0
  traceInfos = []
  isAddtrace = false
  traceVisible: boolean = false
  traceForm: any = {
    trace_title: '',
    customTraceTitle: '',
    image_urls: [],
    remark: ''
  }
  rules = {
    trace_title: {
      required: true,
      message: '请输入类型',
      trigger: 'blur'
    }
  }
  center_car_id = null
  trace_type = null
  trace_id = null
  popoverVisible = false
  reviewTraces = []
  traceFormBack = {}
  isTapDone = false
  currentImages = []
  currentIndex = 0
  showPreviewWindow = false

  mounted () {
    const { center_car_id } = this.$route.query;
    this.center_car_id = center_car_id;
    this.getCarInfo(center_car_id);
    this.getBidRecord(center_car_id);
    this.getTraceInfos(center_car_id);
  }

  async getBidRecord (id) {
    const { code, data } = await this.$rest.carManage.getBidRecord(id);
    if (code !== 0) return;
    this.bidRecords = data;
    this.totalNum = data.length;
  }

  async getCarInfo (id) {
    const { code, data } = await this.$rest.carManage.getCarInfo(id);
    if (code !== 0) return;
    this.carInfo = data
  }

  async getReviewTraces (id) {
    const { code, data } = await this.$rest.carManage.getReviewTraces(id);
    if (code !== 0) return;
    this.reviewTraces = data.infos;
  }

  handleTraceCancel () {
    this.getTraceInfos(this.center_car_id);
    this.traceVisible = false;
  }

  carStatus (transfer_status, auction_status) {
    let result = '';
    if (transfer_status) {
      result = transfer_status === 1 ? '待过户' : '已过户';
    } else {
      result = ['待开拍', '竞拍中', '已拍出', '已流拍', '已成交', '已关闭', '已下架', '待审核', '已驳回'][+auction_status - 1]
    }
    return result;
  }

  listenChildImage (image_urls) {
    this.traceForm.image_urls = image_urls;
  }

  handleTabClick (tab) {
    this.popoverVisible = false;
    if (+tab.name === 0) {
      this.getTraceInfos(this.center_car_id);
    } else {
      this.getBidRecord(this.center_car_id);
    }
    this.searchParams.status = tab.name;
  }

  handleLookBigPic (urls, index) {
    this.currentIndex = +index;
    this.currentImages = this.formatBigImages(urls);
    this.showPreviewWindow = true;
  }

  handleRecord (id) {
    this.popoverVisible = true;
    this.getReviewTraces(id);
  }

  handleGo (path, params) {
    const { href } = this.$router.resolve({ path, query: params });
    window.open(href, '_blank');
  }

  handleOperatetrace (type, item) {
    this.trace_type = type;
    if (type === 'edit') {
      this.trace_id = item.id;
      this.traceForm.image_urls = item.image_urls;
      this.traceForm.trace_title = item.title;
      this.traceForm.remark = item.remark;
    } else {
      this.trace_id = null;
      this.traceForm.image_urls = [];
      this.traceForm.trace_title = '';
      this.traceForm.remark = '';
    }
    this.traceForm.customTraceTitle = type === 'add' ? '新增轨迹' : '编辑轨迹';
    this.traceVisible = true;
  }

  handleBidInfo (row) {
    this.handleGo('/carManage/stock/bidInfo', {
      center_car_id: this.center_car_id,
      auction_id: row.auction_id,
      order_id: row.order_id
    })
  }

  async getTraceInfos (id) {
    const { code, data } = await this.$rest.carManage.getTraceInfos(id);
    if (code !== 0) return;
    this.traceInfos = data.infos;
    this.isAddtrace = data.can_add_trace;
  }

  handleCurrentChange () {
    this.getBidRecord(this.center_car_id);
  }

  async handleSave () {
    let formValid: boolean = true;
    (this.$refs['addTraceForm'] as any).validate((valid) => {
      if (!valid) formValid = false;
    });
    if (!formValid) return
    this.isTapDone = true;

    let res = null;
    let successMsg = ''
    const form: any = Object.assign({}, this.traceForm);
    form.image_urls = this.formatImages(form.image_urls);

    if (this.trace_type === 'add') {
      res = await this.$rest.carManage.postTrace(this.center_car_id, form);
      successMsg = '新增成功';
    } else {
      res = await this.$rest.carManage.editTrace({
        center_car_id: this.center_car_id,
        trace_id: this.trace_id
      }, form);
      successMsg = '编辑成功';
    }
    this.isTapDone = false;
    if (res.code !== 0) return;
    this.$message.success(successMsg);
    this.traceVisible = false;
    this.getTraceInfos(this.center_car_id);
  }

  handleClose (id) {
    this.$confirm('你确定删除该轨迹吗？', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(async () => {
      const { code, message } = await this.$rest.carManage.deleteTrace({
        center_car_id: this.center_car_id,
        trace_id: id
      })
      if (code !== 0) return;
      this.$message.success('删除成功');
      this.getTraceInfos(this.center_car_id);
    }).catch(() => {}); // catch 必须要，否则点击取消时会提示没有绑定取消事件
  }

  @Watch('traceVisible')
  showTraceVisible (val) {
    if (!val) {
      this.getTraceInfos(this.center_car_id);
    }
  }
}
